import { Box, styled } from '@mui/material';
import { Link } from 'react-router-dom';
import COLORS from '@app/colors';

export const FooterLinksContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
`;

export const FooterLink = styled(Link)`
  font-family: 'Inter', serif;
  font-weight: 500;
  font-size: 13px;
  color: ${COLORS.black};
  text-decoration: none;
`;
