import * as yup from 'yup';

const LoginFormValidation = yup
  .object({
    email: yup.string().email('The Email address is incorrect, please try again')
      .required('Please enter your Email'),
    password: yup.string().required('Please enter your Password'),
  });

export const defaultValues = {
  email: '',
  password: '',
};

export default LoginFormValidation;
