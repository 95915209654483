import { Components } from '@mui/material/styles/components';

const MuiCssBaseline: Components['MuiCssBaseline'] = {
  styleOverrides: {
    '*': {
      boxSizing: 'border-box',
      margin: 0,
      padding: 0,
    },
  },
};

export default MuiCssBaseline;
