import { ICourse, IMember } from 'modules/book-ticket';

export enum TicketStatus {
  DECLINED = 'DECLINED',
  APPROVED = 'APPROVED',
  PENDING = 'PENDING',
}

export interface ITicket {
  availability: number;
  comment: string;
  createdAt: string;
  date: string;
  guests: Array<{ id: string; fullName: string }>;
  id: string;
  status: TicketStatus;
  ticketCourses: Array<{ id: string; course: ICourse }>;
  ticketMembers: Array<{ id: string; member: IMember }>;
}

export interface IEditProfileFormValues {
  name: string;
}
