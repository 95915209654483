import { Box, css, Stack, styled } from '@mui/material';
import TextareaAutosize from '@mui/base/TextareaAutosize';
import { StyledTextFieldLabel } from '@ui/TextField/styled';
import COLORS from '@app/colors';

export const EnterCommentContainer = styled(Box)`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      margin-top: 50px;
    }
  `}
`;

export const EnterCommentContent = styled(Box)`
  max-width: 464px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const TextAreaBox = styled(Stack)`
  margin: 80px auto 0 auto;

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      margin: 50px 0;
      width: 100%;
    }
  `}
`;

export const TextAreaTitle = styled(StyledTextFieldLabel)`
  margin-bottom: 12px;
`;

export const TextArea = styled(TextareaAutosize)`
  width: 420px;
  background: white;
  border: 1px solid ${COLORS.black};
  border-radius: 10px;
  padding: 16px;
  font-family: 'Inter', serif;
  font-weight: 400;
  font-size: 15px;
  
  ::placeholder  {
    color: rgba(32, 31, 33, 0.5);
  }

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      width: unset;
    }
  `}
`;
