import * as yup from 'yup';

import { IResetPasswordFormValues } from '../types';

const ResetPasswordFormValidation = yup
  .object({
    email: yup.string().email('The Email address is incorrect, please try again')
      .required(),
  });

export const defaultValues : IResetPasswordFormValues = {
  email: '',
};

export default ResetPasswordFormValidation;
