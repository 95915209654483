import theme from '@app/theme';
import { ThemeProvider } from '@mui/material';
import { RouterProvider } from 'react-router-dom';
import router from '@app/router';
import store from '@app/store';
import { Provider } from 'react-redux';
import CssBaseline from '@mui/material/CssBaseline';
import './index.css';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <CssBaseline />
        <RouterProvider router={router} />
      </Provider>
    </ThemeProvider>
  );
}

export default App;
