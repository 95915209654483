import { Box, styled, Button, css } from '@mui/material';

export const SuccessStepContainer = styled(Box)`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      // TODO: not sure about this
      height: calc(100vh - 180px);
    }
  `}
`;

export const PageSubtitleBox = styled(Box)`
  max-width: 300px;
  margin: 0 auto;
`;

export const BackButton = styled(Button)`
  padding-left: 88px;
  padding-right: 88px;
  margin-top: 80px;
`;

export const Content = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const FooterLinksBox = styled(Box)`
  padding-bottom: 20px;

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      padding-bottom: 0;
    }
  `}
`;
