import { Box, css, styled, Typography } from '@mui/material';

export const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 56px;
  padding-top: 96px;
  height: calc(100vh - 100px);
  
  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      width: 100%;
      height: calc(100vh - 120px);
      padding-bottom: unset;
      padding-top: 50px;
    }
  `}
`;

export const ErrorText = styled(Typography)`
  margin: 25px 0;
  color: ${({ theme }) => theme.palette.error.main}
`;

export const FooterLinksBox = styled(Box)`
  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      margin-top: 50px;
    }
  `}
`;
