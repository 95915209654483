import { Components } from '@mui/material/styles/components';
import { css } from '@mui/material';
import COLORS from '@app/colors';

const root = css`
  border-radius: 10px;
  font-size: 16px;
  font-weight: 500;
  padding: 16px;
  text-transform: none;
`;

const text = css`
  color: ${COLORS.black};
`;

const outlined = css`
  color: ${COLORS.black};
  border: 1px solid #000000;
  
  :hover {
    border: 1px solid #000000;
  }
`;

const containedSuccess = css`
  background: ${COLORS.green};
  color: white
`;

const MuiButton: Components['MuiButton'] = {
  styleOverrides: {
    root,
    text,
    outlined,
    containedSuccess,
  },
};

export default MuiButton;
