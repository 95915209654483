import { css, FormHelperText, styled } from '@mui/material';
import Box from '@mui/material/Box';
import { CalendarPicker as MuiCalendarPicker } from '@mui/x-date-pickers/CalendarPicker';
import COLORS from '@app/colors';
import Typography from '@mui/material/Typography';
import MuiSlider from '@mui/material/Slider';
import Select from '@ui/Select';

export const DateContainer = styled(Box)`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      margin-top: 50px;
    }
  `}  
`;

export const SubtitleBox = styled(Box)`
  display: flex;
  align-self: center;
  max-width: 600px;
  margin-bottom: 80px;

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      max-width: unset;
      margin-bottom: 50px;
    }
  `}
`;

export const CalendarBox = styled(Box)`
  display: flex;
  align-self: center;
  gap: 64px;

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      flex-direction: column;
      justify-content: center;
      gap: 24px;
      width: 100%;
    }
  `}
`;

export const CalendarPicker = styled(MuiCalendarPicker)`
  border-radius: 10px;
  border: 1px solid #282750;
  max-height: unset;
  background: white;

  .MuiPickersCalendarHeader-root {
    border-bottom: 1px solid #282750;
    padding-bottom: 15px;
    max-height: unset;
  }
  
  .MuiDayPicker-header {
    margin-top: 15px;
  }
  
  .MuiPickersCalendarHeader-label {
    color: ${COLORS.green}
  }

  .MuiDayPicker-weekDayLabel {
    color: ${COLORS.green}
  }
  
  .MuiPickersDay-root {
    border-radius: 8px;
    background: unset;
    font-size: 16px;
    font-weight: 300;
    font-family: 'Inter', serif;
  }

  // TODO: remove important later if possible
  .MuiPickersDay-root.Mui-selected {
    background: ${COLORS.green} !important;
  }
  
  .MuiDayPicker-slideTransition {
    min-height: 230px;
  }

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      &.MuiCalendarPicker-root {
        width: 100%;
      }
    }
  `}
 
`;

export const TimeSelect = styled(Select)`
  width: 340px;

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      width: 100%;
    }
  `}
`;

export const RangeBox = styled(Box)`
  margin-top: 24px;

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      margin-bottom: 50px;
    }
  `}
`;

export const RangeBoxTitle = styled(Typography)`
  font-family: 'Inter', serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 140%;
  color: ${COLORS.black};
  margin-bottom: 10px;
`;

export const RangeBoxContent = styled(Box)`
  padding: 25px 0 20px 10px;
  border: 1px solid #000000;
  border-radius: 10px;
  background: white;
`;

export const RangeBoxContentTitle = styled(Typography)`
  font-family: 'Inter', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  color: ${COLORS.black};
  padding: 0 16px 16px 16px;
  border-bottom: 1px solid #EAEAEA;
`;

export const SliderBox = styled(Box)`
  padding: 0 30px;
  margin-top: 68px;
`;

export const Slider = styled(MuiSlider)(() => ({
  color: '#C7C7C7',
  height: 9,
  padding: '15px 0',
  '& .MuiSlider-thumb': {
    height: 28,
    width: 28,
    backgroundColor: '#2F2F2F',
    '&:focus, &:hover, &.Mui-active': {
      boxShadow:
        '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {},
    },
  },
  '& .MuiSlider-valueLabel': {
    fontSize: 15,
    fontWeight: 'normal',
    top: -6,
    backgroundColor: '#2F2F2F',
    padding: '5px 25px',
    borderRadius: '8px',
    // color: theme.palette.text.primary,
    '&:before': {
      display: 'none',
    },
    '& *': {
      background: 'transparent',
      // color: theme.palette.mode === 'dark' ? '#fff' : '#000',
    },
  },
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-rail': {
    opacity: 0.5,
    backgroundColor: '#2F2F2F',
  },
  '& .MuiSlider-mark': {
    display: 'none',
  },
}));
