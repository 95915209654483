import React from 'react';

import { Box, useMediaQuery, useTheme } from '@mui/material';
import COLORS from '@app/colors';

import { Description, InfoContentBox, InfoImageBox, InfoRow, Number, Title } from './styled';
import info1 from '@assets/images/home/info1.png';
import info2 from '@assets/images/home/info2.png';

const Info = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box>
      <InfoRow>
        <InfoImageBox background={info1} />

        <InfoContentBox
          order={isMobile ? 0 : 1}
          background={COLORS.green}
        >
          <Box>
            <Title>
              Our product
            </Title>

            <Description>
              Tee Swap offers a web-based platform for clubs to manage reciprocal
              play and a mobile web-app for members to request to book tee times.
            </Description>

            <br />
            <Description>
              Our platform offers features such as course availability management,
              booking confirmations, reminders, and more.
            </Description>
          </Box>
        </InfoContentBox>
      </InfoRow>

      <InfoRow>
        <InfoContentBox
          order={isMobile ? 1 : 0}
          background={COLORS.black}
        >
          <Box>
            <Title>
              The Solution
            </Title>

            <Number>01</Number>
            <Description>
              Tee Swap offers an all-in-one platform for clubs and members
              to manage reciprocal play.
            </Description>

            <Number>02</Number>
            <Description>
              For clubs, we offer a simple and intuitive interface
              for managing reciprocal play and communicating with members.
            </Description>

            <Number>03</Number>
            <Description>
              For members, we offer an easy-to-use app for
              booking tee times at reciprocal clubs.
            </Description>
          </Box>
        </InfoContentBox>

        <InfoImageBox background={info2} />

      </InfoRow>
    </Box>
  );
};

export default Info;
