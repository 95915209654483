import { HomeLanding } from '@modules/homeLanding';
import { Box } from '@mui/material';

const HomeLandingPage = () => {
  return (
    <Box flex="1">
      <HomeLanding />
    </Box>
  );
};

export default HomeLandingPage;
