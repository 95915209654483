import { Box, css, styled } from '@mui/material';

export const Container = styled(Box)`
  width: 500px;
  margin: 70px auto 0 auto;

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      width: 100%;
      margin-top: 50px;
    }
  `}
`;

export const FormBox = styled(Box)`
  width: 300px;
  margin: 70px auto 0 auto;

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      width: 100%;
    }
  `}
`;

export const FormInputBox = styled(Box)`
  margin: 60px 0 20px 0;
`;
