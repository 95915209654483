import { createApi } from '@reduxjs/toolkit/query/react';
import { buildBaseQuery } from '@app/api';

import {
  ILoginFormValues,
  ILoginResponse,
  IUser,
  IMember,
  IResetPasswordFormValues,
  ISetNewPasswordFormValues,
} from '../types/index';

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: buildBaseQuery('auth'),
  endpoints: (builder) => ({
    login: builder.mutation<{ data: ILoginResponse }, ILoginFormValues>({
      query: (body) => ({
        url: '/login',
        method: 'POST',
        body,
      }),
    }),
    resetPassword: builder.mutation<void, IResetPasswordFormValues>({
      query: (body) => ({
        url: '/password-reset',
        method: 'POST',
        body,
      }),
    }),
    setNewPassword: builder.mutation<void, ISetNewPasswordFormValues & { token: string }>({
      query: (body) => ({
        url: '/password-set',
        method: 'PUT',
        body,
      }),
    }),
    getProfile: builder.query<{
      data: {
        user: IUser;
        member: IMember;
      };
    }, void>({
      query: () => '/profile',
    }),
  }),

});

export const {
  useLoginMutation,
  useGetProfileQuery,
  useLazyGetProfileQuery,
  useResetPasswordMutation,
  useSetNewPasswordMutation,
} = authApi;
