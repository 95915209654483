import { Components } from '@mui/material/styles/components';
import { css } from '@mui/material';

const root = css`
  .MuiInputBase-root {
    border-radius: 10px;
  }
`;

const MuiTextField: Components['MuiTextField'] = {
  styleOverrides: {
    root,
  },
};

export default MuiTextField;
