import Header from '../Header';
import Preview from '../Preview';
import Info from '../Info';
import Features from '../Features';
import Footer from '../Footer';

const Index = () => {
  return (
    <>
      <Header />
      <Preview />
      <Info />
      <Features />
      <Footer />
    </>
  );
};

export default Index;
