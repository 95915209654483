import { css, styled, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import COLORS from '@app/colors';

export const FeaturesContainer = styled(Box)`
  background: #FCF8F6;
  padding: 140px 120px;

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      padding: 20px;
    }
  `}
`;

export const Title = styled(Typography)`
  font-family: 'Source Serif Pro', serif;
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 60px;
  letter-spacing: -0.02em;
  color: ${COLORS.green};
  margin-bottom: 60px;

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      margin: 40px 0;
      font-size: 36px;
      line-height: 105%;
    }
  `}
`;

export const FeatureBoxes = styled(Box)`
  display: flex;
  gap: 40px;

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      flex-direction: column;
      gap: 20px;
    }
  `}
`;

export const FeatureBox = styled(Box)`
  background: #FFFFFF;
  border-radius: 10px;
  padding: 43px 30px;
  width: 100%;

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      padding: 40px 20px;
    }
  `}

`;

export const FeatureBoxTitle = styled(Box)`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 140%;
  color: ${COLORS.green};
`;

export const Feature = styled(Typography)`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 140%;
  color: ${COLORS.black};
  border-top: 1px solid rgba(37, 37, 37, .5);
  padding-top: 20px;
  margin-top: 20px;
`;
