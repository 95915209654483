import { css, styled, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import COLORS from '@app/colors';

export const HeaderContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  padding: 70px 120px 0 120px;

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      flex-direction: column;
      padding: 40px 20px 20px 20px;
      align-items: center;
      justify-content: center;
      width: 100%;
    }
  `}
`;

export const HeaderLogo = styled(Box)`
  img {
    cursor: pointer;
  }

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      img {
        max-width: 180px;
      }
    }
  `}
`;

export const HeaderText = styled(Typography)`
  max-width: 550px;
  font-family: 'Source Serif Pro', serif;
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 60px;
  color: ${COLORS.green};

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      max-width: unset;
      width: 100%;
      margin-top: 55px;
      text-align: center;
      font-size: 36px;
      line-height: 105%;

    }
  `}
`;
