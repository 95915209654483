import { Box, css, styled } from '@mui/material';

export const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 96px;
  height: calc(100vh - 100px);
  
  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      width: 100%;
      height: unset;
      padding-bottom: unset;
      padding-top: 50px;
    }
  `}
`;
