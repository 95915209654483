import { createSlice } from '@reduxjs/toolkit';

import type { PayloadAction } from '@reduxjs/toolkit';

import {
  getAuthInitialState,
  saveUserDataInStorage,
  saveTokenInStorage,
  purgeAuthDataFromStorage,
} from '../helpers/authHelpers';
import { IAuthStore, IMember, IUser } from '../types';
import { AuthStorageKeys } from '../constants';

const initialState: IAuthStore = getAuthInitialState();

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setToken: (state, { payload }: PayloadAction<{ accessToken: string; rememberMe: boolean }>) => {
      if (payload.rememberMe) {
        localStorage.setItem(AuthStorageKeys.REMEMBER_ME, 'true');
      }

      saveTokenInStorage(payload.accessToken);

      state.accessToken = payload.accessToken;
    },
    setUser: (state, { payload }: PayloadAction<{ user: IUser; member: IMember }>) => {
      saveUserDataInStorage(payload);

      state.userInfo.user = payload.user;
      state.userInfo.member = payload.member;
    },
    logout: (state) => {
      purgeAuthDataFromStorage();
      state.accessToken = '';
    },
  },
});

export const { setToken, setUser, logout } = authSlice.actions;
