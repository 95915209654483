export const TimeZone = 'America/New_York'; // 'America/New_York' // 'America/Bogota' // 'US/Eastern'

export type DayHourType = {
  value: string;
  hours: number;
  minutes: number;
  type: 'AM' | 'PM';
}

export const dayHoursList: DayHourType[] = [
  {
    value: '00:00 PM',
    hours: 0,
    minutes: 0,
    type: 'PM',
  },
  // {
  //   value: '00:30 PM',
  //   hours: '00',
  //   minutes: '30',
  //   type: 'PM',
  // },
  {
    value: '01:00 AM',
    hours: 1,
    minutes: 0,
    type: 'AM',
  },
  // {
  //   value: '01:30',
  //   time: '01:30',
  //   type: 'AM',
  // },
  {
    value: '02:00 AM',
    hours: 2,
    minutes: 0,
    type: 'AM',
  },
  // {
  //   value: '02:30',
  //   time: '02:30',
  //   type: 'AM',
  // },
  {
    value: '03:00 AM',
    hours: 3,
    minutes: 0,
    type: 'AM',
  },
  // {
  //   value: '03:30',
  //   time: '03:30',
  //   type: 'AM',
  // },
  {
    value: '04:00 AM',
    hours: 4,
    minutes: 0,
    type: 'AM',
  },
  // {
  //   value: '04:30',
  //   time: '04:30',
  //   type: 'AM',
  // },
  {
    value: '05:00 AM',
    hours: 5,
    minutes: 0,
    type: 'AM',
  },
  // {
  //   value: '05:30',
  //   time: '05:30',
  //   type: 'AM',
  // },
  {
    value: '06:00 AM',
    hours: 6,
    minutes: 0,
    type: 'AM',
  },
  // {
  //   value: '06:30',
  //   time: '06:30',
  //   type: 'AM',
  // },
  {
    value: '07:00 AM',
    hours: 7,
    minutes: 0,
    type: 'AM',
  },
  // {
  //   value: '07:30',
  //   time: '07:30',
  //   type: 'AM',
  // },
  {
    value: '08:00 AM',
    hours: 8,
    minutes: 0,
    type: 'AM',
  },
  // {
  //   value: '08:30',
  //   time: '08:30',
  //   type: 'AM',
  // },
  {
    value: '09:00 AM',
    hours: 9,
    minutes: 0,
    type: 'AM',
  },
  // {
  //   value: '09:30',
  //   time: '09:30',
  //   type: 'AM',
  // },
  {
    value: '10:00 AM',
    hours: 10,
    minutes: 0,
    type: 'AM',
  },
  // {
  //   value: '10:30',
  //   time: '10:30',
  //   type: 'AM',
  // },
  {
    value: '11:00 AM',
    hours: 11,
    minutes: 0,
    type: 'AM',
  },
  // {
  //   value: '11:30',
  //   time: '11:30',
  //   type: 'AM',
  // },
  {
    value: '12:00 PM',
    hours: 12,
    minutes: 0,
    type: 'PM',
  },
  // {
  //   value: '12:30',
  //   time: '12:30',
  //   type: 'PM',
  // },
  {
    value: '01:00 PM',
    hours: 13,
    minutes: 0,
    type: 'PM',
  },
  // {
  //   value: '13:30',
  //   time: '1:30',
  //   type: 'PM',
  // },
  {
    value: '02:00 PM',
    hours: 14,
    minutes: 0,
    type: 'PM',
  },
  // {
  //   value: '14:30',
  //   time: '2:30',
  //   type: 'PM',
  // },
  {
    value: '03:00 PM',
    hours: 15,
    minutes: 0,
    type: 'PM',
  },
  // {
  //   value: '15:30',
  //   time: '3:30',
  //   type: 'PM',
  // },
  {
    value: '04:00 PM',
    hours: 16,
    minutes: 0,
    type: 'PM',
  },
  // {
  //   value: '16:30',
  //   time: '4:30',
  //   type: 'PM',
  // },
  {
    value: '05:00 PM',
    hours: 17,
    minutes: 0,
    type: 'PM',
  },
  // {
  //   value: '17:30',
  //   time: '5:30',
  //   type: 'PM',
  // },
  {
    value: '06:00 PM',
    hours: 18,
    minutes: 0,
    type: 'PM',
  },
  // {
  //   value: '18:30',
  //   time: '6:30',
  //   type: 'PM',
  // },
  {
    value: '07:00 PM',
    hours: 19,
    minutes: 0,
    type: 'PM',
  },
  // {
  //   value: '19:30',
  //   time: '7:30',
  //   type: 'PM',
  // },
  {
    value: '08:00 PM',
    hours: 20,
    minutes: 0,
    type: 'PM',
  },
  // {
  //   value: '20:30',
  //   time: '8:30',
  //   type: 'PM',
  // },
  {
    value: '09:00 PM',
    hours: 21,
    minutes: 0,
    type: 'PM',
  },
  // {
  //   value: '21:30',
  //   time: '9:30',
  //   type: 'PM',
  // },
  {
    value: '10:00 PM',
    hours: 22,
    minutes: 0,
    type: 'PM',
  },
  // {
  //   value: '22:30',
  //   time: '10:30',
  //   type: 'PM',
  // },
  {
    value: '11:00 PM',
    hours: 23,
    minutes: 0,
    type: 'PM',
  },
  // {
  //   value: '23:30',
  //   time: '11:30',
  //   type: 'PM',
  // },
];
