import { css, styled } from '@mui/material';
import TextareaAutosize from '@mui/base/TextareaAutosize';
import COLORS from '@app/colors';

export const StyledTextArea = styled(TextareaAutosize)`
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 10px;
  padding: 16px;
  font-family: 'Inter', serif;
  font-weight: 400;
  font-size: 15px;
  margin-top: 10px;
  width: 100%;
  
  ::placeholder  {
    color: rgba(32, 31, 33, 0.5);
  }
`;
