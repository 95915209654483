import { Box, css, styled, Typography } from '@mui/material';
import MuiButton from '@mui/material/Button';

export const FormBox = styled(Box)`
  width: 600px;
  margin: 0 auto;
  
  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      width: 100%;
    }
  `}
`;

export const PageTitle = styled(Typography)`
  font-family: 'Inter', sans-serif;
  font-weight: 700;
  font-size: 24px;
  margin-bottom: 40px;
`;

export const Button = styled(MuiButton)`
  padding: 16px 65px;
  font-weight: 600;
  font-size: 16px;
  margin-top: 40px;
  margin-bottom: 40px;
`;

export const SuccessBox = styled(Box)`
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
`;
export const SuccessTitle = styled(Typography)`
  font-family: 'Inter', serif;
  font-weight: 700;
  font-size: 24px;
  color: #201F21;
  text-align: center;
`;

export const SuccessDescription = styled(Typography)`
  font-family: 'Inter', serif;
  font-weight: 400;
  font-size: 15px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #000000;
`;
