import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { FetchBaseQueryArgs } from '@reduxjs/toolkit/dist/query/fetchBaseQuery';
import { RootState } from '@app/store';

export const buildBaseQuery = (prefix = '', params?: FetchBaseQueryArgs) => {
  return fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_BASE_URL}/${prefix}`,
    prepareHeaders: (headers, { getState }) => {
      const state = getState() as RootState;

      if (state.auth.accessToken) {
        headers.set('Authorization', `Bearer ${state.auth.accessToken}`);
      }

      return headers;
    },
    ...params,
  });
};
