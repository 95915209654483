import { Box, css, Stack, styled } from '@mui/material';

export const FormBox = styled(Box)`
  width: 300px;
  margin: 0 auto;

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      width: 100%;
    }
  `}
`;

export const FormInputBox = styled(Stack)`
  margin: 60px 0;
  gap: 20px; 
`;

export const SuccessBox = styled(Box)`
  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      width: 100%;
    }
  `}
`;
