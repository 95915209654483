import { createFilterOptions } from '@mui/material/Autocomplete';
import { FilterOptionsState } from '@mui/base/AutocompleteUnstyled/useAutocomplete';
import * as yup from 'yup';

import { MemberOptionType, ISelectMembersFormValues } from '../types';
import { CourseStorageKeys } from '../constants';

export const initialMembersData: ISelectMembersFormValues = {
  members: [],
};

export const getSelectMembersInitialValues = () => {
  const data = localStorage.getItem(CourseStorageKeys.MEMBERS);

  return data ? JSON.parse(data) : initialMembersData;
};

export const saveSelectMembersDataInStorage = (values: ISelectMembersFormValues) => {
  localStorage.setItem(CourseStorageKeys.MEMBERS, JSON.stringify(values));
};

const filter = createFilterOptions<MemberOptionType>();

export const filterOptions = (options: MemberOptionType[], state: FilterOptionsState<MemberOptionType>) => {
  const filtered = filter(options, state);

  const { inputValue } = state;
  const isExisting = options.some((option) => inputValue === option.id);

  if (inputValue !== '' && !isExisting) {
    filtered.push({
      inputValue,
      id: Math.random().toString(),
      isMember: false,
      fullName: `Add guest "${inputValue}"`,
    });
  }

  return filtered;
};

export const getOptionLabel = (option: MemberOptionType | string) => {
  if (typeof option === 'string') {
    return option;
  }

  return option.inputValue ? option.inputValue : option.fullName;
};

export const isOptionEqualToValue = (a: MemberOptionType, b: MemberOptionType) => a.id === b.id;

export const SelectMembersFormValidation = yup
  .object({
    members: yup.array()
      .of(yup.object()
        .shape({
          id: yup.string().required(),
        })
        .required()).required().min(1, 'You must add at least 1 Member to proceed with the reservation.'),
  });
