import React from 'react';

import { Feature, FeatureBox, FeatureBoxes, FeatureBoxTitle, FeaturesContainer, Title } from './styled';

const Info = () => {
  return (
    <FeaturesContainer>
      <Title>Features Included</Title>

      <FeatureBoxes>
        <FeatureBox>
          <FeatureBoxTitle>
            All subscription levels include
            <br />
            the following features:
          </FeatureBoxTitle>

          <Feature>Simple and intuitive interface for managing reciprocal play</Feature>
          <Feature>Web-based platform accessible from any device</Feature>
          <Feature>Member-facing web app for easy booking of tee times</Feature>
          <Feature>Course availability management</Feature>
          <Feature>Booking confirmations and reminders</Feature>
          <Feature>Customer support via phone and email</Feature>
        </FeatureBox>

        <FeatureBox>
          <FeatureBoxTitle>
            For clubs with higher-volume needs or special
            <br />
            requirements, we also offer the following add-on
            <br />
            features:
          </FeatureBoxTitle>

          <Feature>Automated reporting and analytics</Feature>
          <Feature>Custom branding and design</Feature>
          <Feature>Integration with your existing systems</Feature>
        </FeatureBox>

      </FeatureBoxes>
    </FeaturesContainer>
  );
};

export default Info;
