import { FieldError } from 'react-hook-form';

import { CourseStorageKeys } from '../constants';
import { IPickCourseFormValues } from '../types';

export const initialCoursesData: IPickCourseFormValues = {
  courses: [],
};

export const getPickCourseInitialValues = () => {
  const data = localStorage.getItem(CourseStorageKeys.COURSE);

  return data ? JSON.parse(data) : initialCoursesData;
};

export const savePickCourseDataInStorage = (values: IPickCourseFormValues) => {
  localStorage.setItem(CourseStorageKeys.COURSE, JSON.stringify(values));
};

export const pickCourseFormValidationResolver = async (values: IPickCourseFormValues) => {
  const validationErrors: { courses: FieldError[] } = {
    courses: [],
  };

  const indexesToBeValidated = [0, 1];

  indexesToBeValidated.forEach((index) => {
    if (!values.courses[index]) {
      validationErrors.courses[index] = {
        type: 'required',
        message: 'You must pick at least 2 courses to proceed',
      };
    } else {
      delete validationErrors.courses[index];
    }
  });

  return {
    values,
    errors: validationErrors.courses.length ? validationErrors : {},
  };
};
