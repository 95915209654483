import { Box, css, Stack, styled } from '@mui/material';
import { Link } from 'react-router-dom';
import COLORS from '@app/colors';

export const LoginFormBox = styled(Box)`
  width: 300px;
  margin: 0 auto;
  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      width: 100%;
    }
  `}
`;

export const LoginFormInputs = styled(Stack)`
  margin-top: 60px;
  gap: 20px;
`;

export const CheckboxContainer = styled(Box)`
  display: flex;
  align-items: center;
  margin: 16px 0;
  font-family: 'Inter', serif;
  cursor: pointer;
`;

export const ForgotPasswordLink = styled(Link)`
  text-decoration: underline;
  text-align: center;
  margin-top: 20px;
  font-family: 'Inter', serif;
  cursor: pointer;
  display: block;
  color: ${COLORS.black};
`;
