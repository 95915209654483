import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { buildBaseQuery } from '@app/api';

export const requestsApi = createApi({
  reducerPath: 'requestsApi',
  baseQuery: buildBaseQuery(''),
  endpoints: (builder) => ({
    makeRequest: builder.mutation<void, { email: string }>({
      query: (body) => ({
        url: '/requests',
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const { useMakeRequestMutation } = requestsApi;
