import React from 'react';

import { createBrowserRouter } from 'react-router-dom';

import HomeLanding from '@pages//HomeLanding';

const router = createBrowserRouter([
  {
    path: '*',
    element: <HomeLanding />,
  },
]);

export default router;
