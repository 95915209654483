import React, { FC } from 'react';

import { ButtonProps } from '@mui/material';
import Links from '@components/FooterLinks';

import arrowRight from '@assets/icons/arrow-right.svg';
import arrowLeft from '@assets/icons/arrow-left.svg';
import { BackButton, NextButton, StepperButtonsContainer, FooterLinks } from './styled';

interface StepperButtonsProps {
  nextButtonText?: string;
  backButtonText?: string;
  backButtonProps?: ButtonProps;
  nextButtonProps?: ButtonProps;
}

const StepperButtons: FC<StepperButtonsProps> = ({
  backButtonProps,
  nextButtonProps,
  nextButtonText,
  backButtonText,
}) => {
  return (
    <StepperButtonsContainer>
      <BackButton
        variant="text"
        data-testid="back-button"
        {...backButtonProps}
        startIcon={<img src={arrowLeft} alt="arrow" />}
      >
        {backButtonText}
      </BackButton>

      <FooterLinks>
        <Links />
      </FooterLinks>

      <NextButton
        variant="contained"
        data-testid="next-button"
        color="success"
        endIcon={<img src={arrowRight} alt="arrow" />}
        {...nextButtonProps}
      >
        {nextButtonText}
      </NextButton>
    </StepperButtonsContainer>
  );
};

StepperButtons.defaultProps = {
  backButtonText: 'Back',
  nextButtonText: 'Confirm and Proceed',
};

export default StepperButtons;
