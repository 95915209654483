import { createApi } from '@reduxjs/toolkit/query/react';
import { buildBaseQuery } from '@app/api';

import { IHelpCenterFormValues } from '../types/index';

export const helpCenterApi = createApi({
  reducerPath: 'helpCenterApi',
  baseQuery: buildBaseQuery(''),
  endpoints: (builder) => ({
    support: builder.mutation<void, IHelpCenterFormValues>({
      query: (body) => ({
        // TODO: Change later
        url: '/questions',
        method: 'POST',
        body,
      }),
    }),
  }),

});

export const { useSupportMutation } = helpCenterApi;
