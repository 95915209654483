import { css, styled } from '@mui/material';
import Box from '@mui/material/Box';

export const PreviewContainer = styled(Box)`
  display: flex;
  justify-content: center;
  padding: 120px 0;

  img {
    max-width: 950px;
  }


  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      margin-top: 37px;
      margin-bottom: 54px;
      padding: 20px;

      img {
        max-width: 100%;
      }
    }
  `}
`;
