import * as yup from 'yup';

import { ISetNewPasswordFormValues } from '../types';

const SetNewPasswordFormValidation = yup
  .object({
    new: yup.string().required('New password field is required'),
    confirm: yup.string().oneOf([yup.ref('new')], 'Passwords must match'),
  });

export const defaultValues: ISetNewPasswordFormValues = {
  new: '',
  confirm: '',
};

export default SetNewPasswordFormValidation;
