import { Typography, styled, css } from '@mui/material';
import COLORS from '@app/colors';

export const StyledPageTitle = styled(Typography)`
  color: ${COLORS.green};
  font-weight: 700;
  font-size: 48px;
  font-family: 'Source Serif Pro', serif;


  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      font-style: normal;
      font-size: 36px;
      text-align: center;
      letter-spacing: -0.015em;
      padding: 0 30px;
    }
  `}
`;
