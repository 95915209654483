import { FC, ReactNode } from 'react';
import * as React from 'react';

import { Box, FormControl, SelectChangeEvent, SelectProps as MuiSelectProps, SxProps } from '@mui/material';

import { StyledSelectLabel, StyledSelect, StyledSelectTitle, StyledHelperText } from './styled';

export type SelectProps = Omit<MuiSelectProps, 'onChange'> & {
  onChange: (event: SelectChangeEvent, child: React.ReactNode) => void;
  title?: string;
  helperText?: string;
  containerProps?: SxProps;
  label: string;
}

const Select: FC<SelectProps> = ({ title, children, helperText, containerProps, onChange, ...props }) => {
  const handleChange = (event: SelectChangeEvent, child: ReactNode) => {
    onChange(event, child);
  };

  return (
    <Box sx={{ minWidth: 200, ...containerProps }}>
      {title && <StyledSelectTitle>{title}</StyledSelectTitle>}

      <FormControl fullWidth error={props.error}>
        <StyledSelectLabel id="demo-simple-select-label">{props.label}</StyledSelectLabel>
        <StyledSelect
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          {...props}
          // @ts-ignore
          onChange={handleChange}
        >
          {children}
        </StyledSelect>
        {helperText && (<StyledHelperText>{helperText}</StyledHelperText>)}
      </FormControl>
    </Box>
  );
};

export default Select;
