import { createApi } from '@reduxjs/toolkit/query/react';
import { buildBaseQuery } from '@app/api';

import { ITicket, TicketStatus } from '../types';

export const ticketApi = createApi({
  reducerPath: 'ticket',
  keepUnusedDataFor: 0,
  baseQuery: buildBaseQuery(''),
  endpoints: (builder) => ({
    getTickets: builder.query<{ data: ITicket[]; total: number }, { status?: TicketStatus } | undefined>({
      query: (params) => ({
        url: '/tickets',
        method: 'GET',
        params,
      }),
    }),
    changeTicketStatus: builder.mutation<undefined, {
      ticketId: string; status: TicketStatus;
    }>({
      query: ({ ticketId, status }) => ({
        url: `/tickets/${ticketId}/status`,
        method: 'PUT',
        body: { status },
      }),
    }),
  }),

});

export const { useGetTicketsQuery, useChangeTicketStatusMutation } = ticketApi;
