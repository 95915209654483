const COLORS = {
  beige: '#FCF8F6',
  green: '#047E50',
  black: '#252525',
  silver: {
    50: '#B6B6B6',
  },
  red: '#EB5757',
};

export default COLORS;
