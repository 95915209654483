import COLORS from '@app/colors';

import { TicketStatus } from '../types';

export const TicketStatusInfo = {
  [TicketStatus.APPROVED]: {
    text: 'Approved',
    color: COLORS.green,
  },
  [TicketStatus.PENDING]: {
    text: 'Pending',
    color: 'yellow',
  },
  [TicketStatus.DECLINED]: {
    text: 'Declined',
    color: COLORS.red,
  },
};

export const ticketExample = {
  id: 'cf13b3b1-e97b-42e3-be09-f2f451566c8d',
  availability: 80,
  status: TicketStatus.APPROVED,
  comment: "Don't touch, for testing",
  date: '2023-04-25T12:00:00.000Z',
  createdAt: '2023-04-13T10:23:47.765Z',
  ticketCourse: null,
  ticketMembers: [
    {
      id: '8f1b387e-1f7d-4056-bfea-76a1a0726c06',
      member: {
        id: 'b1c147eb-1021-4da5-9157-19f2c123def4',
        number: '13048989811',
        fullName: 'dilaw66762',
        phone: '13048989811',
        email: 'dilaw66762@momoshe.com',
      },
    },
    {
      id: 'c1f46372-9f63-4bd7-88b0-4b273da94f23',
      member: {
        id: '53b05616-a8f8-4a0a-b269-49e49cfa9bf4',
        number: '1245687456',
        fullName: 'M&M',
        phone: '1245687456',
        email: 'mm@gmail.com',
      },
    },
  ],
  ticketCourses: [
    {
      id: '3f3fecdb-6a18-4428-93e8-3b114c2cf986',
      course: {
        id: '7facbeaa-e0fb-4a53-b26e-71218b2b2c52',
        email: 'lilit.fatyan@brainstormtech.io',
        guestFee: 400,
        memberFee: 300,
        address: '1234 Main Street, Middle',
        name: 'book-ticket by lilith',
        phone: '12345678956',
        isActive: true,
      },
    },
    {
      id: 'ae3fa91c-c9b0-47cd-a663-c2ad84dd6b20',
      course: {
        id: '21b76606-8c15-4a61-ba7f-88530e638ff8',
        email: 'lilit.fatyan@brainstormtech.io',
        guestFee: 2400,
        memberFee: 100,
        address: '1234 Main Street, Middle',
        name: 'testing',
        phone: '12345678956',
        isActive: true,
      },
    },
  ],
  guests: [],
};
