import { css, styled, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import COLORS from '@app/colors';

export const InfoRow = styled(Box)`
  display: flex;

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      flex-direction: column;
    }
  `}
`;

export const InfoImageBox = styled(Box)<{ background: string }>`
  width: 50%;
  background-image: url(${(props) => props.background});
  background-size: cover;
  background-repeat: no-repeat;
  height: 630px;

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      width: 100%;
      height: 320px;

    }
  `}
`;

export const InfoContentBox = styled(Box)<{ background: string }>`
  width: 50%;
  background: ${(props) => props.background};
  display: flex;
  align-items: center;
  padding-left: 120px;
  position: relative;

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      width: 100%;
      padding: 40px 20px;
    }
  `}
`;

export const Title = styled(Typography)`
  font-family: 'Source Serif Pro', serif;
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 60px;
  color: white;
  margin-bottom: 50px;

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      font-size: 36px;
      line-height: 105%;
      margin-bottom: 25px;
    }
  `}
`;

export const Description = styled(Typography)`
  font-family: 'Inter', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 140%;
  color: white;
  max-width: 360px;

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      max-width: unset;
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
    }
  `}
`;

export const Number = styled(Typography)`
  background: ${COLORS.green};
  border-radius: 64px;
  padding: 2px 11px;
  display: inline-block;
  font-family: 'Inter', serif;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 140%;
  text-align: center;
  color: #FFFFFF;
  margin-bottom: 17px;
  margin-top: 30px;
`;
