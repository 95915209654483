import { Button as MuiButton, css, Dialog, styled, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import COLORS from '@app/colors';

export const InputBox = styled(Box)`
  display: flex;
  //align-items: center;
  gap: 15px;
  margin-top: 60px;

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      flex-direction: column;
    }
  `}
`;

export const Input = styled('input')`
  border: 1px solid rgba(255, 255, 255, 0.8);
  color: rgba(255, 255, 255, 0.8);
  border-radius: 10px;
  padding: 18px;
  height: 50px;
  width: 300px;
  background: transparent;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;

  ::placeholder {
    color: rgba(255, 255, 255, 0.8);
  }


  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      width: unset;
    }
  `}
`;

export const Button = styled(MuiButton)`
  height: 50px;
  padding-left: 47px;
  padding-right: 47px;
`;

export const Copyright = styled(Typography)`
  position: absolute;
  font-family: 'Inter', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  color: white;
  bottom: 50px;

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      position: unset;
      margin-top: 100px;
    }
  `}
`;

export const SuccessDialog = styled(Dialog)`
  & .MuiPaper-root {
    background: transparent;
  }
`;

export const SuccessDialogContent = styled(Box)`
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 80px;
  gap: 17px;
  border-radius: 10px;
  position: relative;

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      padding: 40px 20px;
    }
  `}
`;

export const SuccessDialogTitle = styled(Typography)`
  font-family: 'Source Serif Pro', serif;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: ${COLORS.green};
`;

export const SuccessDialogDescription = styled(Typography)`
  font-family: 'Inter', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 140%;
  text-align: center;
  color: ${COLORS.black};
  max-width: 272px;

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      max-width: unset;
    }
  `}
`;

export const SuccessDialogClose = styled(Box)`
  position: absolute;
  right: 20px;
  top: 20px;
`;
