export enum PUBLIC_PAGES {
  LANDING = '/landing',
  UI = '/ui',
  LOGIN = '/login',
  RESET_PASSWORD = '/reset-password',
  SET_NEW_PASSWORD = '/set-new-password',
}

export enum PRIVATE_PAGES {
  HOME = '/',
  BOOK_TICKET = '/book-ticket',
  MY_ACCOUNT = '/my-account',
  HELP_CENTER = '/help-center',
}
