import { FC } from 'react';

import { Box, TextFieldProps as MuiTextFieldProps } from '@mui/material';

import { StyledTextField, StyledTextFieldLabel } from './styled';

type TextFieldProps = MuiTextFieldProps & {
  label?: string;
}

const TextField: FC<TextFieldProps> = ({ label, ...props }) => {
  return (
    <Box>
      {label && <StyledTextFieldLabel>{label}</StyledTextFieldLabel>}
      <StyledTextField {...props} />
    </Box>
  );
};

export default TextField;
