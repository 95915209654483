import { Typography, styled, css } from '@mui/material';
import COLORS from '@app/colors';

export const StyledPageSubtitle = styled(Typography)`
  color: ${COLORS.black};
  font-family: 'Inter',serif;
  font-weight: 400;
  font-size: 15px;
  margin-top: 20px;

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      font-style: normal;
      font-size: 13px;
      letter-spacing: -0.01em;
    }
  `}
`;
