import dot from '@assets/icons/dot.svg';
import { FooterLink, FooterLinksContainer } from './styled';

const FooterLinks = () => {
  return (
    <FooterLinksContainer>
      <FooterLink to="/">TERMS OF USE</FooterLink>
      <img src={dot} alt="dot" />
      <FooterLink to="/">CONTACT INFORMATION</FooterLink>
    </FooterLinksContainer>

  );
};

export default FooterLinks;
