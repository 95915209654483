import React from 'react';

import { PreviewContainer } from './styled';
import preview from '@assets/images/home/preview.png';

const Header = () => {
  return (
    <PreviewContainer>
      <img src={preview} alt="preview" />
    </PreviewContainer>
  );
};

export default Header;
