import { FC } from 'react';

import { TypographyProps } from '@mui/material';

import { StyledPageSubtitle } from './styled';

const PageSubtitle: FC<TypographyProps> = ({ children, ...props }) => {
  return (
    <StyledPageSubtitle {...props}>{children}</StyledPageSubtitle>
  );
};

export default PageSubtitle;
