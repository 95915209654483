import { Box, styled, Typography } from '@mui/material';
import COLORS from '@app/colors';

export const TicketsBox = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 96px;
`;

export const NoTicketsText = styled(Typography)`
  font-size: 30px;
  text-align: center;
`;
