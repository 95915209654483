import { Typography } from '@mui/material';

import { HeaderContainer, HeaderLogo, HeaderText } from './styled';
import logo from '@assets/images/logo.svg';

const Header = () => {
  return (
    <HeaderContainer>
      <HeaderLogo>
        <img src={logo} alt="tee-swap" />
      </HeaderLogo>
      <Typography
        sx={{
          position: 'absolute',
          top: '30px',
          right: '30px',
          width: '40px',
          background: 'none',
          boxShadow: 0,
          color: 'green',
          cursor: 'pointer',
        }}
        onClick={() => {
          window.open('https://member.theteeswap.com/', '_blank');
        }}
      >
        Login
      </Typography>
      <HeaderText>
        A universal platform
        for clubs and members to manage reciprocal play.
      </HeaderText>

    </HeaderContainer>
  );
};

export default Header;
