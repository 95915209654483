import * as yup from 'yup';
import dayjs from 'dayjs';
import { dayHoursList } from '@constants/time';

import { CourseStorageKeys } from '../constants';
import { hourToHoursAndMinutes } from '@utils/time';
import { ISelectDateFormValues } from '../types';

export const initialDateData: ISelectDateFormValues = {
  date: null,
  time: '',
  interval: 60,
};

export const getSelectDateInitialValues = () => {
  const data = localStorage.getItem(CourseStorageKeys.DATE);

  if (data) {
    const storageData = JSON.parse(data) as ISelectDateFormValues;

    storageData.date = storageData.date ? dayjs(storageData.date) : null;

    return storageData;
  }

  return initialDateData;
};

export const saveSelectDateDataInStorage = (values: ISelectDateFormValues) => {
  localStorage.setItem(CourseStorageKeys.DATE, JSON.stringify(values));
};

export const selectDateSliderMarks = [
  {
    value: 60,
    label: '1h',
  },
  {
    value: 180,
    label: '3 hrs',
  },
];

const availableHours = [
  '08:00 AM',
  '09:00 AM',
  '10:00 AM',
  '11:00 AM',
  '12:00 PM',
  '01:00 PM',
  '02:00 PM',
  '03:00 PM',
  '04:00 PM',
];

export const selectDateDayHours = dayHoursList.filter((item) => availableHours.includes(item.value));

export const valueLabelFormat = (value: number) => {
  const {
    hours,
    minutes,
  } = hourToHoursAndMinutes(value);

  const minutesText = minutes ? `${minutes}m` : '';

  return `${hours}h ${minutesText}`;
};

export const SelectDateFormValidation = yup
  .object({
    date: yup.mixed().required('You must select the Date of the Reservation to proceed'),
    time: yup.string().required('You must select the Time of the Reservation to proceed'),
  });
