import { Box, styled, Typography, Button as MuiButton, css } from '@mui/material';
import COLORS from '@app/colors';

export const TicketBox = styled(Box)`
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  width: 400px;

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      width: 100%;
    }
  `}
`;

export const Title = styled(Typography)`
  font-family: 'Inter', serif;
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  color: ${COLORS.green};
  margin-top: 20px;
  margin-left: 20px;
`;

export const InfoBlockRow = styled(Box)`
  display: flex;
  gap: 20px;
  margin-top: 20px;
  padding: 0 20px;
`;

export const InfoBlock = styled(Box)`
  width: 100%;
`;

export const InfoBlockTitle = styled(Typography)`
  font-family: 'Inter', serif;
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  color: ${COLORS.black};
  margin-bottom: 7px;
`;

export const InfoBlockText = styled(Typography)`
  font-family: 'Inter', serif;
  font-weight: 400;
  font-size: 13px;
  color: ${COLORS.black};
`;

export const BottomInfoBox = styled(Box)`
  margin-top: auto;
`;

export const ActionButtons = styled(Box)`
  display: flex;
  gap: 10px;
  padding: 20px;
`;

export const Button = styled(MuiButton)`
  padding: 8px 16px;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  
  &.MuiButton-outlined {
    border: 1px solid ${COLORS.silver['50']};
    color: ${COLORS.silver['50']};
  }
`;

export const Status = styled(Typography)`
  font-family: 'Inter', serif;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  text-align: center;
  padding: 32px 0;
  margin-top: 20px;
  border-top: 1px solid ${COLORS.silver['50']};
`;
